import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

export const query = graphql`
  query SitemapBG {
    prismicNavigation(lang: { eq: "bg" }) {
      _previewable
      alternate_languages {
        lang
      }
      data {
        body {
          ... on PrismicNavigationBodyFooterLinkList {
            id
            items {
              link_label
              link {
                isBroken
                link_type
                tags
                target
                type
                uid
                url
                id
              }
            }
            primary {
              title
            }
          }
        }
        body1 {
          ... on PrismicNavigationBody1CompanyAddress {
            id
            primary {
              company_address {
                html
              }
            }
          }
        }
        certification {
          link {
            isBroken
            link_type
            tags
            target
            type
            uid
            url
            id
          }
          logo {
            url(imgixParams: { h: 48 })
          }
        }
        company_display_name
        company_phone
        cookie_accept
        cookie_decline
        cookie_text {
          html
        }
        nav {
          ... on PrismicNavigationNavNavItem {
            id
            primary {
              label
              link {
                isBroken
                link_type
                tags
                target
                type
                uid
                url
                id
              }
            }
            items {
              sub_nav_link {
                isBroken
                link_type
                tags
                target
                type
                uid
                url
                id
              }
              sub_nav_link_label
            }
          }
        }
      }
      id
      lang
      prismicId
    }
    prismicSocial(lang: { eq: "bg" }) {
      data {
        body {
          ... on PrismicSocialBodySocialNetwork {
            items {
              social_network
              social_network_url {
                isBroken
                link_type
                tags
                target
                type
                uid
                url
                id
              }
            }
          }
        }
      }
    }
    allPrismicBlogPost(
      filter: { lang: { eq: "bg" } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          _previewable
          data {
            title
          }
          id
          lang
          prismicId
          url
        }
      }
    }
    allPrismicJobPost(
      filter: { lang: { eq: "bg" } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          _previewable
          data {
            page_title
          }
          id
          lang
          prismicId
          url
        }
      }
    }
    allPrismicPage(filter: { lang: { eq: "bg" } }) {
      edges {
        node {
          _previewable
          data {
            page_title {
              text
            }
          }
          first_publication_date(formatString: "MMMM Do YYYY")
          id
          lang
          prismicId
          url
        }
      }
    }
  }
`

const SitemapENGB = ({ data }) => {
  const navigation = data.prismicNavigation
  const social = data.prismicSocial
  const blogPosts = data.allPrismicBlogPost
  const jobPosts = data.allPrismicJobPost
  const pages = data.allPrismicPage
  return (
    <Layout navigation={navigation} social={social}>
      <Seo
        title="Sitemap | Unicard"
        description="A list of pages across the Unicard website"
      />
      <div className="relative overflow-hidden mb-10">
        <div className="max-w-screen-xl mx-auto">
          <div className="relative overflow-hidden mb-10 max-w-5xl text-center w-full mx-auto featured fade sm:px-6">
            <h1 className="text-5xl lg:text-6xl">Карта на сайта</h1>
          </div>
        </div>
      </div>
      <div className="max-w-5xl mx-auto relative px-4 sm:px-6 pb-4 sm:pb-8">
        <h2>Страници</h2>
        <ul className="ml-5">
          {pages.edges !== null &&
            pages.edges.length > 0 &&
            pages.edges.map((page, index) => {
              return (
                <React.Fragment key={`page-${index}`}>
                  {page.node.url !== "/bg/404" &&
                    page.node.url !== "/sitemap" &&
                    page.node.url !== "/success" && (
                      <li className="mb-2">
                        <Link
                          className="py-3 underline transform transition duration-150 ease-in-out hover:no-underline hover:text-blue-500 visited:text-blue-600"
                          to={page.node.url}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: page.node.data.page_title.text,
                            }}
                          />
                        </Link>
                      </li>
                    )}
                </React.Fragment>
              )
            })}
        </ul>
        {blogPosts.edges !== null && blogPosts.edges.length > 0 && (
          <>
            <h2>Новини</h2>
            <ul className="ml-5">
              {blogPosts.edges.map((blogpost, index) => {
                return (
                  <li className="mb-2" key={`post-${index}`}>
                    <Link
                      className="py-3 underline transform transition duration-150 ease-in-out hover:no-underline hover:text-blue-500 visited:text-blue-600"
                      to={blogpost.node.url}
                    >
                      <div>{blogpost.node.data.title}</div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </>
        )}
        {jobPosts.edges !== null && jobPosts.edges.length > 0 && (
          <>
            <h2>Кариера</h2>
            <ul className="ml-5">
              {jobPosts.edges.map((job, index) => {
                return (
                  <li className="mb-2" key={`post-${index}`}>
                    <Link
                      className="py-3 underline transform transition duration-150 ease-in-out hover:no-underline hover:text-blue-500 visited:text-blue-600"
                      to={job.node.url}
                    >
                      <div>{job.node.data.page_title}</div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </>
        )}
      </div>
    </Layout>
  )
}
export default SitemapENGB
